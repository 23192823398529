*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    
}

:root {
  --primary: #2b2f38;
  --primary-light: #545862;
  --primary-dark: #000512;
  --secondary: #ccc5bf;
  --secondary-light: #f7ece3;
  --secondary-dark: #938a82;
  --light-text: #f7ece3;
  --dark-text:#282a2e;
  --accent: #ff691e;
  --accent-dark: #e65a14;
  --success:#02a802;
  --error:#ee0101;
}

body { 
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--secondary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* No me ha dado tiempo a mirar como crear un styled component con el select de Formik
  creo que no acepta className, luego no puedo styled(Field)`*/
#marca{
  width: 100%;
    border-radius: 5px;
    border: 1px solid var(--primary-light);
    font-size: 14px;
    padding: 5px;
    color: var(--dark-text);    
}

#marca:hover{
  outline: none;
  border: 1px solid var(--primary);
}